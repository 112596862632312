<template>
  <v-sheet v-bind="propsCompSheet" data-cy="documents-shares-delete">
    <template v-if="objectId">
      <div class="mb-8">
        {{ $t("documentsShares.deleteConfirmation") }}
      </div>
      <v-container class="pa-0 mb-8">
        <div>{{ documentShare.url }}</div>
      </v-container>
      <div class="d-flex">
        <v-btn
          v-bind="propsButton"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          :to="{ name: 'documents-index' }"
          data-cy="button-cancel"
          >{{ $t("common.cancel") }}</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="
            authCanByUserId(
              'deleteOwn.documentsShares',
              documentShare.ownerId
            ) || authCan('deleteOthers.documentsShares')
          "
          v-bind="propsButtonTrash"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          @click="clickDelete"
          data-cy="button-delete"
          >{{ $t("common.yes") }}</v-btn
        >
      </div>
    </template>
    <v-alert v-else type="error">
      {{ $t("documents.error") }}
    </v-alert>
  </v-sheet>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import Permissions from "@/modules/auth/mixins/Permissions.mixin";

export default {
  name: "DocumentDelete",
  mixins: [ComponentStatus, Permissions],
  props: {
    objectId: {
      type: String,
      default: "",
    },
    documentId: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      return true;
    },
    documentShare() {
      return this.$store.getters["documentsShares/readById"](
        this.documentId,
        this.objectId
      );
    },
    url() {
      if (this.documentShare && this.documentShare.token) {
        let route = this.$router.resolve({
          name: "documents-public-show",
          params: {
            token: this.documentShare.token,
          },
        });

        return `${location.protocol}//${location.host}${route.href}`;
      }
      return "";
    },
  },
  watch: {
    objectId: {
      immediate: true,
      handler: function () {
        if (!this.changed) {
          this.setStatusReading();
          this.$store
            .dispatch("documentsShares/readById", {
              id: this.objectId,
              documentId: this.documentId,
            })
            .then((/* result */) => {
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
            });
        }
      },
    },
  },
  methods: {
    clickDelete() {
      this.sendDelete();
    },
    sendDelete() {
      this.setStatusDeleting();
      this.$store
        .dispatch("documentsShares/delete", {
          documentId: this.documentId,
          id: this.objectId,
        })
        .then((/* result */) => {
          this.setStatusDeleteSuccess();
          this.$store.commit("status/showSuccess");
          this.$router.push({
            name: "documents-index",
          });
        })
        .catch((/* error */) => {
          this.setStatusDeleteError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
